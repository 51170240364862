import React from 'react';

import Layout from '../components/Layout';

// import Scroll from '../components/Scroll';

import stemhacks from '../assets/images/stemhacks.jpg';
import waterpolo from '../assets/images/waterpolo.jpg';
import car_final from '../assets/images/car_final.jpg';
import config from '../../config';

const IndexPage = () => (
  <Layout>
    <section id="banner">
      <div className="inner">
        <h2>{config.heading}</h2>
        <p>{config.subHeading}</p>
        {/*<ul className="actions special">
          <li>
            <Scroll type="id" element="one">
              <a href="/#" className="button primary">
                Explore
              </a>
            </Scroll>
          </li>
        </ul>*/}
      </div>
      {/* <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll> */}
    </section>

    <section id="one" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            About Me
          </h2>
          <p>
            I am in my fourth year of Mechatronics Engineering and Management at McMaster University, and have 20 months of co-op experience at companies such as Bell Canada and Rockwell Automation.
            I am currently spending a semester abroad at KTH Royal Institute of Technology in Stockholm, Sweden where I am taking courses from their Masters of Aerospace and Masters of Interactive Media Technology programs.
            After my exchange semester, I will be spending 12 weeks in Menlo Park working as a Software Engineering Intern with Meta.
            I am interested in software and robotics, and hope to work in the aerospace industry after graduation.
          </p>
        </header>
        <ul className="icons major">
          <li>
            <span className="icon solid fa-code major style1">
            </span>
          </li>
          <li>
            <span className="icon solid fa-rocket major style2">
            </span>
          </li>
          <li>
            <span className="icon solid fa-robot major style3">
            </span>
          </li>
        </ul>
      </div>
    </section>

    <section id="two" className="wrapper alt style2">
      <section className="spotlight">
        <div className="image">
          <img src={car_final} alt="" />
        </div>
        <div className="content">
          <h2>
            Engineering
          </h2>
          <p>
            My enrolment in a unique program that combines software, electrical and mechanical engineering with business courses allows me to explore opportunities in multiple disciplines.
            I am involved with several engineering clubs including the McMaster Mechatronics Society, McMaster Makers club, McMaster Women in Engineering, and McMaster EngiQueers.
            The photo shown here is a prototype of the bluetooth Arduino car workshop I taught as a part of McMaster Makers.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={waterpolo} alt="" />
        </div>
        <div className="content">
          <h2>
            Athletics
          </h2>
          <p>
            I am the goalie on the McMaster women's varsity water polo team. We won the silver medal at the 2017 National Collegiate Water Polo Championships.
            I was awarded team MVP for the 2019 season, and have been given the Marauder Scholar Award for academic excellence while participating on a varsity sport every year I have competed on the team.
          </p>
        </div>
      </section>
      <section className="spotlight">
        <div className="image">
          <img src={stemhacks} alt="" />
        </div>
        <div className="content">
          <h2>
            Mentoring
          </h2>
          <p>
            As a tutor with over 7 years of experience, I've had the opportunity to work with several students ranging from kindergarteners to university students. 
            I also enjoy volunteering as a mentor at events that get young students interested in science and engineering such as Go ENG Girl and STEM Hacks. As an
            advocate for diversity in engineering at McMaster, I have acted as a mentor at multiple events hosted by Women in Engineering and EngiQueers.
          </p>
        </div>
      </section>
    </section>

    <section id="three" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Projects</h2>
          <p>
            Below are some projects I've completed in high school, university, and side projects
          </p>
        </header>
        <ul className="features">
          <li className="icon solid fa-beer">
            <h3>Basketbeer Crusher</h3>
            <p>
              For the 2021 McMaster Designathon, my team and I designed a product to make cleaning up cans more fun. We followed the engineering design process and ended with a fully functional 3D model. Video pitch of our product can be found <a href="https://youtu.be/a1cBBnVdN2M">here</a>
            </p>
          </li>
          <li className="icon solid fa-stethoscope">
            <h3>Engineering Inquiry</h3>
            <p>
              I led a team of 3 other engineering students to analyze the affects of Covid-19 in Code Red Regions of Hamilton, and what could be done to improve the current situation. <a href="https://youtu.be/gucTanYAW6k">Our solution</a> won third place out of over 60 teams.
            </p>
          </li>
          <li className="icon fa-paper-plane">
            <h3>Laser Cutting</h3>
            <p>
              I design and laser cut custom pieces for my friends and family. This includes keychains for clubs and teams as well as other one-off items. Photos of my designs can be found <a href="/LaserCutting">here</a>
            </p>
          </li>
          <li className="icon solid fa-code">
            <h3>Space Invaders</h3>
            <p>
              As a capstone project for AP Computer Science, I built Space Invaders using Java. View the project <a href="https://github.com/yangl56/SpaceInvaders/">here!</a>
            </p>
          </li>
          <li className="icon solid fa-hands-helping">
            <h3>IMPACT Project</h3>
            <p>
              I led a team of 4 engineering students through the engineering design process to design and build a prototype of a garbage disposal system for a client who is a wheelchair user
              whichwe then pitched to a panel of professors.
            </p>
          </li>
          <li className="icon solid fa-cog">
            <h3>Gear Train Hand</h3>
            <p>
              In my first year engineering design course, I worked with 2 other engineering students to use Autodesk Inventor to 3D model a gear train that mimics the motion of a hand and 
              well as a custom chassis. We then printed and assembled a working prototype of the design.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Other fun facts about me</h2>
          
        </header>
        <ul>
          <li>I was an extra in a Disney movie.</li>
          <li>I have my PADI Open Water Diver certification.</li>
          <li>I can solve a Rubik's cube in around 45 seconds.</li>
          <li>I like watching Jeopardy! and Wheel of Fortune.</li>
        </ul>
        {/*<ul className="actions stacked">
          <li>
            <a href="/#" className="button fit primary">
              Activate
            </a>
          </li>
          <li>
            <a href="/#" className="button fit">
              Learn More
            </a>
          </li>
        </ul>*/}
      </div>
    </section>
  </Layout>
);

export default IndexPage;
